
  
  
 /* Remove background color from the entire DatePicker */
.react-datepicker {
  border: none;
  box-shadow: none;
}

/* Remove background color from the DatePicker calendar */
.react-datepicker__month-container {
  border: none;
  background-color: transparent;
}

/* Remove background color from the header (month and year) */
.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}



/* Remove background color from today highlight */
.react-datepicker__day--today {
  background-color: transparent;
  border-color: transparent;
}

/* Optional: Remove background color from the week numbers */
.react-datepicker__week-number {
  background-color: transparent;
}

/* Remove background color from the navigation buttons (if needed) */
.react-datepicker__navigation {
  border: none;
  background-color: transparent;
}




    